import * as jsyaml from 'js-yaml';
import { runtimeLoaded, addInitializer } from '../stores';
import { handleFetchError } from '../utils';
import { getLogger } from '../logger';
const logger = getLogger('py-env');
// Premise used to connect to the first available runtime (can be pyodide or others)
let runtime;
runtimeLoaded.subscribe(value => {
    runtime = value;
});
export class PyEnv extends HTMLElement {
    shadow;
    wrapper;
    code;
    environment;
    runtime;
    env;
    paths;
    constructor() {
        super();
        this.shadow = this.attachShadow({ mode: 'open' });
        this.wrapper = document.createElement('slot');
    }
    connectedCallback() {
        logger.info("The <py-env> tag is deprecated, please use <py-config> instead.");
        this.code = this.innerHTML;
        this.innerHTML = '';
        const env = [];
        const paths = [];
        this.environment = jsyaml.load(this.code);
        if (this.environment === undefined)
            return;
        for (const entry of Array.isArray(this.environment) ? this.environment : []) {
            if (typeof entry == 'string') {
                env.push(entry);
            }
            else if (entry && typeof entry === 'object') {
                const obj = entry;
                for (const path of Array.isArray(obj.paths) ? obj.paths : []) {
                    if (typeof path === 'string') {
                        paths.push(path);
                    }
                }
            }
        }
        this.env = env;
        this.paths = paths;
        async function loadEnv() {
            logger.info("Loading env: ", env);
            await runtime.installPackage(env);
        }
        async function loadPaths() {
            logger.info("Paths to load: ", paths);
            for (const singleFile of paths) {
                logger.info(`  loading path: ${singleFile}`);
                try {
                    await runtime.loadFromFile(singleFile);
                }
                catch (e) {
                    //Should we still export full error contents to console?
                    handleFetchError(e, singleFile);
                }
            }
            logger.info("All paths loaded");
        }
        addInitializer(loadEnv);
        addInitializer(loadPaths);
    }
}
