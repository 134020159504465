import { writable } from 'svelte/store';
import './logger';
/*
A store for Runtime which can encompass any
runtime, but currently only has Pyodide as its offering.
*/
export const runtimeLoaded = writable();
export const loadedEnvironments = writable({});
export const navBarOpen = writable(false);
export const componentsNavOpen = writable(false);
export const componentDetailsNavOpen = writable(false);
export const mainDiv = writable(null);
export const currentComponentDetails = writable([]);
export const scriptsQueue = writable([]);
export const initializers = writable([]);
export const postInitializers = writable([]);
export const globalLoader = writable();
export const appConfig = writable();
export const addToScriptsQueue = (script) => {
    scriptsQueue.update(scriptsQueue => [...scriptsQueue, script]);
};
export const addInitializer = (initializer) => {
    initializers.update(initializers => [...initializers, initializer]);
};
export const addPostInitializer = (initializer) => {
    postInitializers.update(postInitializers => [...postInitializers, initializer]);
};
