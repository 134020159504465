import { runtimeLoaded, loadedEnvironments, globalLoader, initializers, postInitializers, scriptsQueue, appConfig, } from './stores';
import { createCustomElements } from './components/elements';
import { getLogger } from './logger';
const logger = getLogger('pyscript/runtime');
export const version = "<<VERSION>>";
let loader;
globalLoader.subscribe(value => {
    loader = value;
});
let initializers_;
initializers.subscribe((value) => {
    initializers_ = value;
});
let postInitializers_;
postInitializers.subscribe((value) => {
    postInitializers_ = value;
});
let scriptsQueue_;
scriptsQueue.subscribe((value) => {
    scriptsQueue_ = value;
});
let appConfig_ = {
    autoclose_loader: true
};
appConfig.subscribe((value) => {
    if (value) {
        appConfig_ = value;
    }
});
/*
Runtime class is a super class that all different runtimes must respect
and adhere to.

Currently, the only runtime available is Pyodide as indicated by the
`RuntimeInterpreter` type above. This serves as a Union of types of
different runtimes/interpreters which will be added in near future.

The class has abstract methods available which each runtime is supposed
to implement.

Methods available handle loading of the interpreter, initialization,
running code, loading and installation of packages, loading from files etc.

For an example implementation, refer to the `PyodideRuntime` class
in `pyodide.ts`
*/
export class Runtime extends Object {
    /**
     * initializes the page which involves loading of runtime,
     * as well as evaluating all the code inside <py-script> tags
     * along with initializers and postInitializers
     * */
    async initialize() {
        loader?.log('Loading runtime...');
        await this.loadInterpreter();
        const newEnv = {
            id: 'default',
            runtime: this,
            state: 'loading',
        };
        runtimeLoaded.set(this);
        // Inject the loader into the runtime namespace
        // eslint-disable-next-line
        this.globals.set('pyscript_loader', loader);
        loader?.log('Runtime created...');
        loadedEnvironments.update(environments => ({
            ...environments,
            [newEnv['id']]: newEnv,
        }));
        // now we call all initializers before we actually executed all page scripts
        loader?.log('Initializing components...');
        for (const initializer of initializers_) {
            await initializer();
        }
        loader?.log('Initializing scripts...');
        for (const script of scriptsQueue_) {
            void script.evaluate();
        }
        scriptsQueue.set([]);
        // now we call all post initializers AFTER we actually executed all page scripts
        loader?.log('Running post initializers...');
        // Finally create the custom elements for pyscript such as pybutton
        createCustomElements();
        if (appConfig_ && appConfig_.autoclose_loader) {
            loader?.close();
        }
        for (const initializer of postInitializers_) {
            await initializer();
        }
        // NOTE: this message is used by integration tests to know that
        // pyscript initialization has complete. If you change it, you need to
        // change it also in tests/integration/support.py
        logger.info('PyScript page fully initialized');
    }
}
